<template>
  <div 
    style="width:100%;height:100%;background-color:transparent;">
    <!--
    <position-layout :modal="modal" :customStyle="customStyle"/>
    -->
    <!--
    <stack-layout v-if="show" :modal="modal" :customStyle="customStyle"/>
    -->
  </div>
</template>

<script>

export default {
  name: 'Home',
  props: ['redirectPath'],
  data () {
    return {
    }
  },
  created () {
    let self = this
    setTimeout(function () {
      if (self.redirectPath) {
        self.$router.push({ path: self.redirectPath })
      }
    }, 500)
  }
}
</script>
